<template>
  <div class="machine">
    <query-header :handle-click-add-btn="handleClickAddBtn" :handle-query="handleQuery"
                  :handle-refresh="handleRefresh"
                  :query="queryForm" :select-options="Object.values(deployEnvListMap)"
                  :show-add-btn="userInfo.authArr.includes('CLUSTER_CREATE')"
                  add-btn-text="添加集群" placeholder="请输入集群ID/名称查询"/>
    <a-table :columns="clusterColumns" :data-source="clusterList" :locale="{emptyText: '暂无数据'}"
             :rowKey="record => record.id"
             size="small">
      <template slot="cluster_name" slot-scope="record">
        <span>{{ record.clusterName }}/{{ record.clusterDesc }}</span>
        <cc-iconfont v-if="userInfo.authArr.includes('CLUSTER_UPDATE_DESC')" :size="8" name="edit"
                     style="margin-right: 4px;margin-left: 4px;"
                     @click.native="handleClickEditClusterNameBtn(record)"/>
      </template>
      <template slot="deployEnvType" slot-scope="record">
        <span>{{ deployEnvListMap[record.deployEnvType] && deployEnvListMap[record.deployEnvType].name }}</span>
      </template>
      <template slot="region" slot-scope="record">
        {{ regionList[record.region] }}
      </template>
      <template slot="num" slot-scope="record">
        <span>{{ record.runningCount }}/{{ record.workerCount }}</span>
      </template>
      <template slot="actions" slot-scope="record">
        <a-button v-if="userInfo.authArr.includes('WORKER_LIST')" size="small" type="link"
                  @click="goMachineList(record)">机器列表
        </a-button>
        <a-popconfirm v-if="userInfo.authArr.includes('CLUSTER_DELETE')" cancel-text="取消" ok-text="确认" title="确定删除该集群吗？"
                      @confirm="handleDeleteCluster(record)">
          <a-button size="small" type="link">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal v-model="showEditClusterNameModal" :closable=false :mask-closable=false :width="320"
             cancelText="取消"
             okText="保存" wrapClassName="have-footer" @ok="handleUpdateClusterName">
      <div class="edit-cluster-name-modal">
        <a-input v-model="clusterDesc" :maxLength="256" :minLength="2" autoSize placeholder="请输入集群名字"
                 style="width: 280px;margin-bottom: 10px;height: 62px;" type="textarea"/>
        <div>长度为2-256个字符，以大小字母或中文开头，可包含数字，"_"或"-"</div>
      </div>
    </a-modal>
    <a-modal v-model="showAddClusterModal" :closable=false :mask-closable=false :width="438" title="创建集群">
      <div class="add-cluster-modal">
        <a-form-model ref="addClusterFormRef" :label-col="labelCol" :model="newClusterForm"
                      :rules="newClusterFormRule" :wrapper-col="wrapperCol">
          <a-form-model-item label="集群名称" prop="clusterDesc">
            <a-input v-model="newClusterForm.clusterDesc" style="width:280px;"/>
          </a-form-model-item>
          <a-form-model-item label="云或机房名称" prop="deployEnvType">
            <!--            <a-radio-group v-model="newClusterForm.deployEnvType" button-style="solid">-->
            <!--              <a-radio-button :value="env.value" :key="env.value" v-for="env in Object.values(deployEnvListMap)">-->
            <!--                {{ env.name }}-->
            <!--              </a-radio-button>-->
            <!--            </a-radio-group>-->
            <cc-cluster-type-select v-model="newClusterForm.deployEnvType"
                                    :deployEnvList="Object.values(deployEnvListMap)"/>
          </a-form-model-item>
          <a-form-model-item label="地区" prop="region">
            <cc-region-select v-model="newClusterForm.region" :env="newClusterForm.deployEnvType"/>
          </a-form-model-item>
        </a-form-model>
        <div class="footer">
          <a-button type="primary" @click="handleAddCluster">保存</a-button>
          <a-button @click="showAddClusterModal=false">取消</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ACTIONS_TYPE } from '@/store/actions';
import QueryHeader from '../components/QueryHeader.vue';

export default {
  name: 'Cluster',
  components: { QueryHeader },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      value: '',
      searchType: 'all',
      searchInput: '',
      queryForm: {
        type: 'all',
        text: ''
      },
      showEditClusterNameModal: false,
      showAddClusterModal: false,
      clusterDesc: '',
      selectedCluster: {},
      clusterFilterInfo: {
        deployEnvType: '',
        query: ''
      },
      page: 1,
      pageSize: 20,
      clusterList: [],
      newClusterForm: {
        clusterDesc: '',
        deployEnvType: 'ALIBABA_CLOUD_HOSTED',
        region: ''
      },
      newClusterFormRule: {
        clusterDesc: [
          {
            required: true,
            message: '名称不能为空',
            trigger: 'blur'
          }
        ],
        deployEnvType: [
          {
            required: true,
            message: '环境不能为空',
            trigger: 'blur'
          }
        ],
        region: [
          {
            required: true,
            message: '地区不能为空',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  computed: {
    clusterColumns() {
      const {
        deployEnvType,
        query
      } = this.clusterFilterInfo;
      const columns = [
        {
          title: '集群ID/名称',
          filteredValue: [query] || null,
          key: 'cluster_name',
          onFilter: (value, record) => (record.clusterName && record.clusterName.includes(value))
            || (record.clusterDesc && record.clusterDesc.includes(value)),
          scopedSlots: { customRender: 'cluster_name' }
        }, {
          title: '类型',
          key: 'deployEnvType',
          filteredValue: [deployEnvType] || null,
          onFilter: (value, record) => value === 'all' || (record.deployEnvType && record.deployEnvType.includes(value)),
          scopedSlots: { customRender: 'deployEnvType' }
        }, {
          title: '区域',
          scopedSlots: { customRender: 'region' }
        }, {
          title: '可用数量/机器数量',
          scopedSlots: { customRender: 'num' }
        }, {
          title: '操作',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ];
      return columns;
    },
    ...mapState({
      deployEnvListMap: (state) => state.deployEnvListMap,
      userInfo: (state) => state.userInfo,
      regionList: (state) => state.regionList
    })
  },
  methods: {
    handlePageChange(page) {
      this.page = page;
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    handleQuery() {
      const {
        type,
        text
      } = this.queryForm;
      this.clusterFilterInfo = {
        deployEnvType: type,
        query: text
      };
    },
    async getClusterList() {
      const res = await this.$services.getClusterList({ data: {} });
      if (res.success) {
        this.clusterList = res.data;
        this.handleQuery();
      }
    },
    handleClickAddBtn() {
      this.showAddClusterModal = true;
    },
    async handleAddCluster() {
      this.$refs.addClusterFormRef.validate(async (valid) => {
        if (valid) {
          const res = await this.$services.createCluster({
            data: this.newClusterForm,
            msg: '添加集群成功'
          });
          if (res.success) {
            this.showAddClusterModal = false;
            await this.getClusterList();
          }
        } else {
          this.$Message.error('缺少参数!');
        }
      });
    },
    handleRefresh() {
      this.getClusterList();
    },
    async handleDeleteCluster(cluster) {
      const {
        id
      } = cluster;
      const data = {
        clusterId: id
      };
      const res = await this.$services.deleteCluster({
        data,
        msg: '删除集群成功'
      });

      if (res.success) {
        await this.getClusterList();
      }
    },
    goMachineList(cluster) {
      this.$router.push({
        name: 'System_Machine_List',
        params: { clusterId: cluster.id }
      });
    },
    handleClickEditClusterNameBtn(cluster) {
      this.selectedCluster = cluster;
      this.clusterDesc = cluster.clusterDesc;
      this.showEditClusterNameModal = true;
    },
    async handleUpdateClusterName() {
      const {
        ...cluster
      } = this.selectedCluster;
      if (this.clusterDesc) {
        const data = {
          clusterId: cluster.id,
          clusterDesc: this.clusterDesc
        };
        const res = await this.$services.updateClusterDesc({
          data,
          msg: '更新名称成功'
        });

        if (res.success) {
          this.getClusterList();
          this.showEditClusterNameModal = false;
        }
      }
    }
  },
  created() {
    this.$store.dispatch(ACTIONS_TYPE.GET_DEPLOY_ENV_LIST);
    this.getClusterList();
  }
};
</script>

<style lang="less" scoped>
//.edit-cluster-name-modal {
//  padding: 20px;
//}
//
//.add-cluster-modal {
//  padding: 20px;
//}

.machine {

  .operation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }
}
</style>
